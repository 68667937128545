<template>
   <v-dialog v-model="dialog" max-width="500px" >
          <template v-slot:activator="{ on, attrs }">
                <v-btn :color="`${zamuPurple2}`" dark class="mb-2 mr-4"  v-bind="attrs" v-on="on">
                Add user
                </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-alert v-if="notification !== ''"  dense text :type="`${actionClass}`" class="mt-5" >
                  {{ notification }}
                 </v-alert>
                <v-form  ref="form" v-model="valid" lazy-validation >
                    <v-row no-gutters class="ma-0">
                        <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="mr-13">
                            <label for="">Name</label>
                            <v-text-field
                            v-model="name"
                            :rules="nameRules"
                            required
                            outlined
                            dense
                            ></v-text-field>
                        </v-col>
                         <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="mr-13">
                            <label for="">Username</label>
                            <v-text-field
                            v-model="username"
                            :rules="usernameRules"
                            required
                            outlined
                            dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="12" md="12 " sm="12" xs="12" >
                            <label for="">E-mail</label>
                            <v-text-field
                            v-model="email"
                            :rules="emailRules"
                            required
                            outlined
                            dense
                            ></v-text-field>
                        </v-col>
                         <v-col cols="12" lg="12" md="12" sm="12" xs="12" >
                            <v-text-field v-model="password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="[passwordRules.required, passwordRules.min]" :type="show1 ? 'text' : 'password'" name="input-10-1" label="Password" hint="At least 8 characters" counter @click:append="show1 = !show1"></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="12" md="12" sm="12" xs="12" >
                            <v-text-field block v-model="verify" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="[passwordRules.required, passwordMatch]" :type="show1 ? 'text' : 'password'" name="input-10-1" label="Confirm Password" counter @click:append="show1 = !show1"></v-text-field>
                        </v-col>
                         <v-card-actions dark>
                            <v-spacer></v-spacer>
                             <v-btn
                                color="blue darken-1"
                                @click="close"
                                dark
                            >
                                Cancel
                            </v-btn>

                            <v-btn
                            :disabled="!valid || loading"
                            :color="`${zamuYellow3}`"
                            class="mr-4 zamu-sub-heading "
                            @click="save"
                            dark
                            >
                            Save User
                            <v-progress-circular
                                indeterminate
                                color="primary"
                                class="ml-4"
                                :size="20"
                                v-if="loading"
                                ></v-progress-circular>
                            </v-btn>
                         </v-card-actions>

                    </v-row>

                </v-form>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
</template>

<script>
import {  mapMutations } from 'vuex';

import coreMixin from '@/mixins/coreMixin';
import colorMixin from '@/mixins/colorMixin';


export default {
    name: 'AddUser',
    mixins: [coreMixin, colorMixin],
    data () {
        return {
            formTitle: 'Add New user',
            dialog: false,
             valid: false,
             loading: false,
            name: '',
            username: '',
            email: '',
            select: null,
            password: "",
            verify: "",
            show1: false,
            notification: '',
            actionClass: '',

        };
    },
    computed: {
        passwordMatch() {
        return () => this.password === this.verify || "Password must match";
        }
    },
    methods: {
    ...mapMutations(['setUpdateStatus']),
    save1() {
        this.setUpdateStatus(true);


    },
        
  async save () {
        this.setUpdateStatus(false);

 
        const formvalid = this.$refs.form.validate();
        if(!formvalid) {
            return;
        }

           this.loading = true;

               const payload = {
                    name: this.name,
                    email: this.email,
                    username: this.username,
                    password: this.password,
                    app_id : 1
                };
                const fullPayload = {
                    app: this.apiUrl,
                    params: payload,
                    endpoint: 'auth/signup',
                };
                  try {
                    const res = await this.axiosPostRequest(fullPayload);
                    this.loading = false;
                    this.notification = res.status === 200 ? 'User saved successful' : res.data.message;
                    this.actionClass = res.status === 200 ? 'success' : 'error';

                    if(res.status === 200 ) {
                       this.setUpdateStatus(true);
                        this.$refs.form.reset();
                        this.notification = '';
                        this.actionClass = '';
                        this.close()
                    }

                } catch (error) {
                    this.notification = 'User failed to save. Contact the admin';
                    this.actionClass = 'error';
                    this.loading = false;
                    return error.response;
                }
      
      },
      close() {
        this.dialog = false
      }
    }

}
</script>

<style>

</style>